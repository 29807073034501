import React from 'react';
import style from './cardContent.module.scss';

const CardContent = ({children}) => (
    <div className={style.cardContent}>
        {children}
    </div>
);

export default CardContent;
