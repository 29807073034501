import React from 'react';
import {graphql } from 'gatsby';
import { injectIntl } from 'react-intl';
import { LanguageLayout } from './../containers/layouts';
import PageIntro from './../components/pageIntro/';
import Wrapper from './../components/wrapper';
import WrapperContent from './../components/wrapperContent';
import Grid from './../components/grid';
import GridActions from './../components/gridActions';
import Card from './../components/card';
import CardImage from './../components/cardImage';
import CardContent from './../components/cardContent';
import CardActions from './../components/cardActions';
import BlendImage from './../components/blendImage';
import Button, { ButtonIcon } from './../components/buttons';
import NewsletterForm from './../containers/newsletterForm';
import { H3 } from './../components/headings';

export const NewsOverviewTemplate = ({ intl, title, description, news, currentPage, numPages, slug }) => {
    const prevNavDisabled = (currentPage === (numPages -1));
    const nextNavDisabled = (currentPage === 0);

    return (
        <>
            <PageIntro color="thinair">
                <Wrapper>
                    <WrapperContent>
                        <PageIntro.title>{title}</PageIntro.title>
                        <PageIntro.description html>{description}</PageIntro.description>
                    </WrapperContent>
                </Wrapper>
            </PageIntro>
            <Wrapper>
                <WrapperContent>
                    <Grid direction="row" wrap>
                        {news.map((newsItem, index) => (
                            <Grid.Item xs={12} md={6} key={`newsItem-${index}`}>
                                <Card>
                                    <CardImage>
                                        <BlendImage
                                            fluid
                                            src={newsItem.frontmatter.listimage.childImageSharp.fluid}
                                        />
                                    </CardImage>
                                    <CardContent>
                                        <H3>{newsItem.frontmatter.title}</H3>
                                        <p>{newsItem.frontmatter.metadata.excerpt}</p>
                                    </CardContent>
                                    <CardActions>
                                        <Button
                                            to={newsItem.fields.slug}
                                            type="GatbsyLink"
                                            secondary={true}
                                            primary={false}
                                            large={true}
                                        >
                                            {intl.formatMessage({id: 'news.readMoreLabel'})}
                                        </Button>
                                    </CardActions>
                                </Card>
                            </Grid.Item>
                        ))}
                        <Grid.Item xs={12}>
                            <GridActions>
                                <ButtonIcon type="GatsbyLink" icon="arrowLeft" disabled={prevNavDisabled} to={(currentPage+1 >= (numPages -1)) ? `${slug}${currentPage+1}` : ''} />
                                <ButtonIcon type="GatsbyLink" icon="arrowRight" disabled={nextNavDisabled} to={(currentPage-1 > 0) ? `${slug}${currentPage-1}` : slug} />
                            </GridActions>
                        </Grid.Item>
                    </Grid>
                </WrapperContent>
                <WrapperContent color="blue">
                    <NewsletterForm />
                </WrapperContent>
            </Wrapper>
        </>
    );
};

const NewsOverviewTemplateWithIntl = injectIntl(NewsOverviewTemplate);

const NewsOverview = (props) => {
    const { frontmatter, fields } = props.data.markdownRemark;
    const totalCount = props.data.allMarkdownRemark.totalCount;
    const news = props.data.allMarkdownRemark.nodes;
    const { currentPage, numPages, slug } = props.pageContext;

    return (
        <LanguageLayout location={props.location}>
            <NewsOverviewTemplateWithIntl
                title={frontmatter.title}
                description={fields.frontmattermd.pageDescriptionText.html}
                totalCount={totalCount}
                news={news}
                currentPage={currentPage}
                numPages={numPages}
                slug={slug}
            />
        </LanguageLayout>
    );
};

export default NewsOverview;

export const pageQuery = graphql`
query NewsOverviewTemplate($langKey: String!, $skip: Int!, $limit: Int!) {
    markdownRemark(fields: {langKey: {eq: $langKey}}, frontmatter: {templateKey: {eq: "news-overview"}}) {
        fields {
            frontmattermd {
                pageDescriptionText {
                    html
                }
            }
        }
        frontmatter {
          title
        }
    }
    allMarkdownRemark(
        sort: { fields: [frontmatter___date], order: DESC }
        limit: $limit
        skip: $skip
        filter: {
            frontmatter: {
                templateKey: {
                    eq: "news-page"
                }
            },
            fields: {
                langKey: {
                    eq: $langKey
                }
            }
        }
        ) {
        totalCount
        nodes {
          id
          frontmatter {
            title
            metadata {
              excerpt
            }
            listimage {
                publicURL
                childImageSharp {
                    fluid(maxWidth: 710) {
                        ...GatsbyImageSharpFluid_noBase64
                    }
                }
              }
          }
          fields {
            slug
            langKey
          }
        }
    }
  }`;
