import React from 'react';
import style from './cardActions.module.scss';

const CardActions = ({ children }) => (
    <div className={style.cardActions}>
        {children}
    </div>
);

export default CardActions;
