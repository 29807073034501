import React from 'react';
import style from './card.module.scss';

const Card = ({ children, ...rest}) => (
    <div className={style.card} {...rest}>
        {children}
    </div>
);

export default Card;
