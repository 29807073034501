import React from 'react';
import style from './gridActions.module.scss';

const GridActions = ({ children }) => (
    <div className={style.gridActions}>
        {children}
    </div>
);

export default GridActions;
